import React, { useState, useEffect, useRef, useCallback } from 'react';
import { axiosInstance } from './auth';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  Drawer, CssBaseline, Container, AppBar, Toolbar, Typography, IconButton, Tooltip,
  Box, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemIcon, ListItemText,
  Snackbar, TextField, LinearProgress, CircularProgress, Badge
} from '@mui/material';
import {
  Assignment, EmojiEvents, ExitToApp,
  Home, Add, Remove, List as ListIcon, Edit,
  Brightness4, Brightness7,
  CalendarToday, Dashboard as DashboardIcon, CheckCircle,
  VisibilityOff, Gavel, Menu,
  Favorite, Cake, Toys, Chat as ChatIcon,
  Poll, Settings, ViewKanban
} from '@mui/icons-material';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { getTheme, fetchUserTheme, updateUserTheme, fetchSubmissiveTheme } from './theme';
import { logout, isAuthenticated, getRole } from './auth';
import { fetchPoints, updatePoints, incrementPoints, decrementPoints } from './points';
import { fetchChastityPeriod, updateChastityPeriod, incrementChastity, decrementChastity } from './chastity';
import { fetchWelcomeMessage, updateWelcomeMessage } from './welcomeMessage';
import Dashboard from './dashboard';
import RewardsAndPunishments from './RewardsAndPunishments';
import RulesKinksAndToys from './RulesKinksAndToys';
import TasksAndCompletions from './TasksAndCompletions';
import NotificationBell from './notifications';
import Login from './login';
import Chat from './chat';
import SpotChecks from './spotChecks';
import FirstLoginWizard from './FirstLoginWizard';
import WizardPortal from './WizardPortal';
import { SettingsProvider, useSettings } from './settings';
import CenteredLoading from './CenteredLoading';
import TutorialManager from './TutorialManager';
import { useUnreadMessages } from './useUnreadMessages';
import WorkflowBuilder from './WorkflowBuilder';
import SafeBoardGame from './BoardGame';

const NavigationRail = ({ navigate, role }) => {
  const [unreadCount] = useUnreadMessages();
  const theme = useTheme();

  const chatMenuItem = {
    label: 'Chat',
    icon: (
      <Badge badgeContent={unreadCount} color="error">
        <ChatIcon />
      </Badge>
    ),
    path: '/chat'
  };

  const dominantMenuItems = [
    { label: 'Calendar', icon: <CalendarToday />, path: '/dashboard#calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'WorkflowBuilder', icon: <AutoFixHighIcon />, path: '/WorkflowBuilder' }, 
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    chatMenuItem,
    { label: 'Tasks', icon: <Assignment />, path: '/tasks-and-completions#tasks' },
    { label: 'Completions', icon: <CheckCircle />, path: '/tasks-and-completions#completions' },
    { label: 'Spot Checks', icon: <VisibilityOff />, path: '/tasks-and-completions#spot-checks' },
    { label: 'Rewards', icon: <EmojiEvents />, path: '/rewards-and-punishments#rewards' },
    { label: 'Punishments', icon: <Gavel />, path: '/rewards-and-punishments#punishments' },
    { label: 'Wishlist', icon: <ListIcon />, path: '/rewards-and-punishments#wishlist' },
    { label: 'Rules', icon: <Gavel />, path: '/rules-kinks-and-toys#rules' },
    { label: 'Kinks', icon: <Favorite />, path: '/rules-kinks-and-toys#kinks' },
    { label: 'Special Days', icon: <Cake />, path: '/rules-kinks-and-toys#special-days' },
    { label: 'Toys', icon: <Toys />, path: '/rules-kinks-and-toys#toys' },
    { label: 'Surveys', icon: <Poll />, path: '/rules-kinks-and-toys#surveys' },
    { label: 'Settings', icon: <Settings />, path: '/rules-kinks-and-toys#settings' },
    { label: 'BoardGame', icon: <VideogameAssetIcon />, path: '/boardgame' },
  ];

  const submissiveMenuItems = [
    { label: 'Calendar', icon: <CalendarToday />, path: '/dashboard#calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    chatMenuItem,
    { label: 'Tasks', icon: <Assignment />, path: '/tasks-and-completions#tasks' },
    { label: 'Spot Checks', icon: <VisibilityOff />, path: '/tasks-and-completions#spot-checks' },
    { label: 'Rewards', icon: <EmojiEvents />, path: '/rewards-and-punishments#rewards' },
    { label: 'Punishments', icon: <Gavel />, path: '/rewards-and-punishments#punishments' },
    { label: 'Wishlist', icon: <ListIcon />, path: '/rewards-and-punishments#wishlist' },
    { label: 'Rules', icon: <Gavel />, path: '/rules-kinks-and-toys#rules' },
    { label: 'Kinks', icon: <Favorite />, path: '/rules-kinks-and-toys#kinks' },
    { label: 'Special Days', icon: <Cake />, path: '/rules-kinks-and-toys#special-days' },
    { label: 'Toys', icon: <Toys />, path: '/rules-kinks-and-toys#toys' },
    { label: 'Surveys', icon: <Poll />, path: '/rules-kinks-and-toys#surveys' },
    { label: 'BoardGame', icon: <Settings />, path: '/boardgame' },
  ];

  const menuItems = role === 'dominant' ? dominantMenuItems : submissiveMenuItems;

  return (
    <Box sx={{
      width: 40,
      borderRight: 1,
      borderColor: 'divider',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 2,
      gap: 1,
      bgcolor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'fixed',
      top: 64,
      bottom: 0,
      zIndex: 1200,
      '& .MuiIconButton-root': {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          bgcolor: theme.palette.primary.dark,
        },
      }
    }}>
      {menuItems.map((item) => (
        <Tooltip key={item.label} title={item.label} placement="right">
          <IconButton
            onClick={() => navigate(item.path)}
            sx={{ 
              width: 48,
              height: 48,
              borderRadius: 1,
            }}
          >
            {item.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

const DrawerMenu = ({ navigate, role }) => {
  const [unreadCount] = useUnreadMessages();
  const theme = useTheme();

  const chatMenuItem = {
    label: 'Chat',
    icon: (
      <Badge badgeContent={unreadCount} color="error">
        <ChatIcon />
      </Badge>
    ),
    path: '/chat'
  };

  const dominantMenuItems = [
    { label: 'Calendar', icon: <CalendarToday />, path: '/dashboard#calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'WorkflowBuilder', icon: <AutoFixHighIcon />, path: '/WorkflowBuilder' },    
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    chatMenuItem,
    { label: 'Tasks', icon: <Assignment />, path: '/tasks-and-completions#tasks' },
    { label: 'Completions', icon: <CheckCircle />, path: '/tasks-and-completions#completions' },
    { label: 'Spot Checks', icon: <VisibilityOff />, path: '/tasks-and-completions#spot-checks' },
    { label: 'Rewards', icon: <EmojiEvents />, path: '/rewards-and-punishments#rewards' },
    { label: 'Punishments', icon: <Gavel />, path: '/rewards-and-punishments#punishments' },
    { label: 'Wishlist', icon: <ListIcon />, path: '/rewards-and-punishments#wishlist' },
    { label: 'Rules', icon: <Gavel />, path: '/rules-kinks-and-toys#rules' },
    { label: 'Kinks', icon: <Favorite />, path: '/rules-kinks-and-toys#kinks' },
    { label: 'Special Days', icon: <Cake />, path: '/rules-kinks-and-toys#special-days' },
    { label: 'Toys', icon: <Toys />, path: '/rules-kinks-and-toys#toys' },
    { label: 'Surveys', icon: <Poll />, path: '/rules-kinks-and-toys#surveys' },
    { label: 'Settings', icon: <Settings />, path: '/rules-kinks-and-toys#settings' },
    { label: 'BoardGame', icon: <Settings />, path: '/boardgame' },
  ];
  const submissiveMenuItems = [
    { label: 'Calendar', icon: <CalendarToday />, path: '/dashboard#calendar' },
    { label: 'Kanban', icon: <ViewKanban />, path: '/dashboard#kanban' },
    { label: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard#dashboard' },
    chatMenuItem,
    { label: 'Tasks', icon: <Assignment />, path: '/tasks-and-completions#tasks' },
    { label: 'Spot Checks', icon: <VisibilityOff />, path: '/tasks-and-completions#spot-checks' },
    { label: 'Rewards', icon: <EmojiEvents />, path: '/rewards-and-punishments#rewards' },
    { label: 'Punishments', icon: <Gavel />, path: '/rewards-and-punishments#punishments' },
    { label: 'Wishlist', icon: <ListIcon />, path: '/rewards-and-punishments#wishlist' },
    { label: 'Rules', icon: <Gavel />, path: '/rules-kinks-and-toys#rules' },
    { label: 'Kinks', icon: <Favorite />, path: '/rules-kinks-and-toys#kinks' },
    { label: 'Special Days', icon: <Cake />, path: '/rules-kinks-and-toys#special-days' },
    { label: 'Toys', icon: <Toys />, path: '/rules-kinks-and-toys#toys' },
    { label: 'Surveys', icon: <Poll />, path: '/rules-kinks-and-toys#surveys' },
    { label: 'BoardGame', icon: <Settings />, path: '/boardgame' },
  ];

  const menuItems = role === 'dominant' ? dominantMenuItems : submissiveMenuItems;

  return (
    <List sx={{
      bgcolor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      height: '100%',
      '& .MuiListItem-root': {
        '&:hover': {
          bgcolor: theme.palette.primary.dark,
        },
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiListItemText-root': {
        color: theme.palette.primary.contrastText,
      }
    }}>
      {menuItems.map((item) => (
        <ListItem 
          button 
          key={item.label} 
          onClick={() => navigate(item.path)}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );
};

const PointsEditor = ({ points, setPoints, role, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newPoints, setNewPoints] = useState(points);

  useEffect(() => {
    setNewPoints(points);
  }, [points]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedPoints = await updatePoints(newPoints);
      setPoints(updatedPoints);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating points:', error);
    }
  };

  const handleIncrement = async (amount) => {
    try {
      const updatedPoints = await incrementPoints(amount);
      setPoints(updatedPoints);
    } catch (error) {
      console.error('Error incrementing points:', error);
    }
  };

  const handleDecrement = async (amount) => {
    try {
      const updatedPoints = await decrementPoints(amount);
      setPoints(updatedPoints);
    } catch (error) {
      console.error('Error decrementing points:', error);
    }
  };

  if (isLoading) {
    return <CenteredLoading />;
  }

return (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2, mr: 1.5 }}>
    {role === 'dominant' && (
      <>
        <IconButton 
          onClick={() => handleDecrement(10)} 
          size="medium" 
          sx={{ color: 'white', p: 0.5 }}
        >
          <Remove fontSize="small" />
        </IconButton>
        <IconButton 
          onClick={() => handleDecrement(1)} 
          size="small" 
          sx={{ color: 'white', p: 0.2 }}
        >
          <Remove fontSize="small" />
        </IconButton>
      </>
    )}
    {isEditing && role === 'dominant' ? (
      <form onSubmit={handleSubmit} style={{ display: 'inline' }}>
        <TextField
          type="number"
          value={newPoints}
          onChange={(e) => setNewPoints(parseInt(e.target.value))}
          onBlur={() => setIsEditing(false)}
          size="small"
          sx={{ width: 60, mx: 1 }}
          autoFocus
        />
      </form>
    ) : (
      <Typography
        variant="body3"
        component="span"
        onClick={() => role === 'dominant' && setIsEditing(true)}
        sx={{ 
          mx: { xs: 0.2, sm: 1 }, // Reduced margin on mobile
          cursor: role === 'dominant' ? 'pointer' : 'default', 
          color: 'white',
          fontSize: { xs: '0.9rem', sm: '1.1rem' },
          whiteSpace: 'nowrap', // Prevent line breaks
        }}
      >
        {points}{'\u00A0'}pts {/* Using non-breaking space and abbreviated "pts" */}
      </Typography>
    )}
    {role === 'dominant' && (
      <>
        <IconButton 
          onClick={() => handleIncrement(1)} 
          size="small" 
          sx={{ color: 'white', p: 0.2 }}
        >
          <Add fontSize="small" />
        </IconButton>
        <IconButton 
          onClick={() => handleIncrement(10)} 
          size="small" 
          sx={{ color: 'white', p: 0.5 }}
        >
          <Add />
        </IconButton>
      </>
    )}
  </Box>
  );
};

const ChastityEditor = ({ chastityDays, setChastityDays, role, isLoading }) => {
  const { settings } = useSettings();
  const [isEditing, setIsEditing] = useState(false);
  const [newDays, setNewDays] = useState(chastityDays);

  useEffect(() => {
    setNewDays(chastityDays);
  }, [chastityDays]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateChastityPeriod(newDays);
      setChastityDays(response.days);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating chastity period:', error);
    }
  };

  const handleIncrement = async (amount) => {
    try {
      const response = await incrementChastity(amount);
      setChastityDays(response.days);
    } catch (error) {
      console.error('Error incrementing chastity period:', error);
    }
  };

  const handleDecrement = async (amount) => {
    try {
      const response = await decrementChastity(amount);
      setChastityDays(response.days);
    } catch (error) {
      console.error('Error decrementing chastity period:', error);
    }
  };

  const label = settings.isPermanentChastity ? 'Lock in' : 'Chastity';

  if (!settings.chastityEnabled) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2, mr: 1.5 }}>
      {role === 'dominant' && (
        <>
          <IconButton 
            onClick={() => handleDecrement(7)} 
            size="small" 
            disabled={chastityDays < 7}
            sx={{ color: 'white' }}
          >
            <Remove fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={() => handleDecrement(1)} 
            size="small" 
            disabled={chastityDays === 0}
            sx={{ color: 'white' }}
          >
            <Remove fontSize="small" />
          </IconButton>
        </>
      )}
      {isEditing && role === 'dominant' ? (
        <form onSubmit={handleSubmit} style={{ display: 'inline' }}>
          <TextField
            type="number"
            value={newDays}
            onChange={(e) => setNewDays(Math.max(0, parseInt(e.target.value)))}
            onBlur={() => setIsEditing(false)}
            size="small"
            sx={{ width: 60, mx: 1 }}
            autoFocus
          />
        </form>
      ) : (
        <Typography
          variant="body3"
          component="span"
          onClick={() => role === 'dominant' && setIsEditing(true)}
          sx={{ 
            mx: { xs: 0.2, sm: 1 }, // Reduced margin on mobile
            cursor: role === 'dominant' ? 'pointer' : 'default', 
            color: 'white',
            fontSize: { xs: '0.9rem', sm: '1.1rem' },
            whiteSpace: 'nowrap', // Prevent line breaks
          }}
        >
          {label}:{'\u00A0'}{chastityDays}d {/* Using non-breaking space and abbreviated "d" */}
        </Typography>
      )}
      {role === 'dominant' && (
        <>
          <IconButton 
            onClick={() => handleIncrement(1)} 
            size="small"
            sx={{ color: 'white' }}
          >
            <Add fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={() => handleIncrement(7)} 
            size="small"
            sx={{ color: 'white' }}
          >
            <Add fontSize="small" />
          </IconButton>
        </>
      )}
    </Box>
  );
};

const usePollStats = (isAuth, interval = 10000) => {
  const [stats, setStats] = useState({
    points: 0,
    chastityDays: 0,
    isLoading: true,
    error: null
  });

  const updateStats = useCallback((newStats) => {
    setStats(prevStats => {
      // Only update if values have changed
      if (prevStats.points !== newStats.points || 
          prevStats.chastityDays !== newStats.chastityDays) {
        return {
          ...prevStats,
          ...newStats,
          isLoading: false,
          error: null
        };
      }
      return prevStats;
    });
  }, []);

  const updatePoints = useCallback((newPoints) => {
    setStats(prevStats => ({
      ...prevStats,
      points: newPoints
    }));
  }, []);

  const updateChastityDays = useCallback((newDays) => {
    setStats(prevStats => ({
      ...prevStats,
      chastityDays: newDays
    }));
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchStats = async () => {
      if (!isAuth) return;

      try {
        const [pointsResponse, chastityResponse] = await Promise.all([
          fetchPoints(),
          fetchChastityPeriod()
        ]);

        if (isSubscribed) {
          updateStats({
            points: pointsResponse,
            chastityDays: chastityResponse.days
          });
        }
      } catch (error) {
        if (isSubscribed) {
          setStats(prev => ({
            ...prev,
            isLoading: false,
            error: 'Failed to fetch stats'
          }));
        }
      }
    };

    fetchStats(); // Initial fetch
    const intervalId = setInterval(fetchStats, interval);

    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
  }, [isAuth, interval, updateStats]);

  return {
    ...stats,
    updatePoints,
    updateChastityDays
  };
};

const AppContent = ({ isAuth, setIsAuth, userRole, setUserRole, handleLogout, showWizard, setShowWizard }) => {
  const { settings, updateSettings } = useSettings();
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isPointsLoading, setIsPointsLoading] = useState(true);
  const [isChastityLoading, setIsChastityLoading] = useState(true);
  const { points, chastityDays, updatePoints, updateChastityDays } = usePollStats(isAuth);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditingWelcome, setIsEditingWelcome] = useState(false);
  const [newWelcomeMessage, setNewWelcomeMessage] = useState('');
  const [currentTheme, setCurrentTheme] = useState('dark');
  const [originalTheme, setOriginalTheme] = useState('light');
  const [partnerTheme, setPartnerTheme] = useState('dark');
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const [navStyle, setNavStyle] = useState('rail');
  const [unreadCount] = useUnreadMessages();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLandscape = useMediaQuery('(orientation: landscape)');
  
  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get('/settings');
      updateSettings(response.data);
      setIsSettingsLoaded(true);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      setIsSettingsLoaded(true);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        if (isAuth) {
          await fetchSettings();
          const { points: initialPoints, chastityDays: initialChastityDays } = await fetchPointsAndChastity() || {};
          await fetchWelcomeMessageData();
          await checkFirstLogin();
          await fetchPointsAndChastity();
          
          //console.log('Setting initial data:', { initialPoints, initialChastityDays });
          if (initialPoints !== undefined) updatePoints(initialPoints);
          if (initialChastityDays !== undefined) updateChastityDays(initialChastityDays);
          setDataLoaded(true);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchInitialData();
  }, [isAuth, userRole]);

  useEffect(() => {
    const fetchThemes = async () => {
      if (isAuth) {
        const userTheme = await fetchUserTheme(userRole);
        setCurrentTheme(userTheme);
        setOriginalTheme(userTheme);
        if (userRole === 'dominant') {
          const subTheme = await fetchSubmissiveTheme();
          setPartnerTheme(subTheme);
        }
      }
    };
    fetchThemes();
  }, [isAuth, userRole]);

  const toggleTheme = async () => {
    const newTheme = currentTheme === originalTheme ? 'dark' : originalTheme;
    setCurrentTheme(newTheme);
    if (userRole === 'dominant') {
      try {
        await updateUserTheme(newTheme);
      } catch (error) {
        console.error('Error updating theme:', error);
      }
    }
  };

  // If the eventListener fails just check every 120 secs
  useEffect(() => {
    const refreshSettings = () => {
      if (isAuth) {
        fetchSettings();
      }
    };
  
    const intervalId = setInterval(refreshSettings, 120000); // Refresh every 120s
  
    return () => clearInterval(intervalId);
  }, [isAuth]);

 // Check for settings
  useEffect(() => {
    const handleSettingsChange = () => {
      fetchSettings();
      fetchPointsAndChastity();
    };
  
    window.addEventListener('settingsChanged', handleSettingsChange);
  
    return () => {
      window.removeEventListener('settingsChanged', handleSettingsChange);
    };
  }, []);

  const checkFirstLogin = async () => {
    try {
      const response = await axiosInstance.get('/check-first-login');
      setShowWizard(response.data.is_first_login);
    } catch (error) {
      console.error('Failed to check first login status:', error);
    }
  };

  const handleWizardComplete = async () => {
    try {
      await axiosInstance.post('/complete-wizard');
      setShowWizard(false);
      // Refresh user data after wizard completion
      fetchPointsAndChastity();
      fetchWelcomeMessageData();
      fetchUserTheme(userRole).then(setCurrentTheme);
    } catch (error) {
      console.error('Failed to complete wizard:', error);
    }
  };

  useEffect(() => {
  const handleMessage = (event) => {
    if (event.data && event.data.type === 'GET_AUTH_TOKEN') {
      const token = localStorage.getItem('token');
      event.ports[0].postMessage({ type: 'AUTH_TOKEN', token: token });
    } else if (event.data && event.data.type === 'TOKEN_EXPIRED') {
      handleLogout();
    }
  };

  navigator.serviceWorker.addEventListener('message', handleMessage);

  // Cleanup function
  return () => {
    navigator.serviceWorker.removeEventListener('message', handleMessage);
  };
}, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash && contentRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const setPointsWithCheck = useCallback((newPoints) => {
    updatePoints(prevPoints => {
      // Only update if the value has changed
      if (prevPoints !== newPoints) {
        return newPoints;
      }
      return prevPoints;
    });
  }, []);
  
  const setChastityDaysWithCheck = useCallback((newDays) => {
    updateChastityDays(prevDays => {
      // Only update if the value has changed
      if (prevDays !== newDays) {
        return newDays;
      }
      return prevDays;
    });
  }, []);

  const fetchPointsAndChastity = async () => {
    try {
      setIsPointsLoading(true);
      setIsChastityLoading(true);
      
      const [pointsResponse, chastityResponse] = await Promise.all([
        fetchPoints(),
        fetchChastityPeriod()
      ]);
  
      // Use the new state setters that check for changes
      setPointsWithCheck(pointsResponse);
      setChastityDaysWithCheck(chastityResponse.days);
      
      return { 
        points: pointsResponse, 
        chastityDays: chastityResponse.days 
      };
    } catch (error) {
      console.error('Error fetching points and chastity:', error);
      setErrorMessage('Failed to fetch points and chastity. Please try again.');
      return null;
    } finally {
      setIsPointsLoading(false);
      setIsChastityLoading(false);
    }
  };

  const fetchWelcomeMessageData = async () => {
    try {
      const message = await fetchWelcomeMessage();
      setWelcomeMessage(message);
      setNewWelcomeMessage(message);
    } catch (error) {
      setErrorMessage('Failed to fetch welcome message. Please try again.');
    }
  };

  const handleUpdateWelcomeMessage = async () => {
    try {
      const updatedMessage = await updateWelcomeMessage(newWelcomeMessage);
      setWelcomeMessage(updatedMessage);
      setIsEditingWelcome(false);
    } catch (error) {
      setErrorMessage('Failed to update welcome message. Please try again.');
    }
  };

  if (isLoading || !dataLoaded) {
    return <CenteredLoading />;
  }

return (
  <SettingsProvider>
    <ThemeProvider theme={getTheme(currentTheme)}>
      <CssBaseline />
      {!isAuth ? (
        <Login setAuth={(auth) => {
          setIsAuth(auth);
          setUserRole(getRole());
        }} />
      ) : showWizard ? (
        <WizardPortal>
          <FirstLoginWizard onComplete={handleWizardComplete} />
        </WizardPortal>
      ) : (
        <TutorialManager role={userRole}>
          <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            {isLargeScreen && (
              navStyle === 'drawer' ? (
                <Drawer
                  variant="permanent"
                  sx={{
                    width: 240,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: 240,
                      boxSizing: 'border-box',
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      position: 'fixed',
                      top: 0, // Height of AppBar
                      height: 'calc(100%)',
                      zIndex: 1200
                    },
                  }}
                >
                  <Toolbar />
                  <DrawerMenu navigate={navigate} role={userRole} unreadCount={unreadCount} />
                </Drawer>
              ) : (
                <NavigationRail navigate={navigate} role={userRole} unreadCount={unreadCount} />
              )
            )}
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                width: '100%',
                marginLeft: isLargeScreen ? (navStyle === 'rail' ? '40px' : '20px') : 0,
                marginTop: '64px', // Height of AppBar
                overflow: 'hidden'
              }}>
              <AppBar 
                position="fixed"
                sx={{ 
                  width: isLargeScreen && navStyle === 'rail' ? `100%` : '100%',
                  zIndex: 1300
                }}
              >
                {isLargeScreen ? (
                  // Desktop header
                  <Toolbar sx={{ minHeight: 50, display: 'flex', justifyContent: 'space-between' }}>
                    {/* Left side with menu and welcome message */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2, 
                      minWidth: 0, // Allows text truncation
                      flex: 1 // Takes available space
                    }}>
                      <IconButton 
                        color="inherit" 
                        onClick={() => setNavStyle(prev => prev === 'rail' ? 'drawer' : 'rail')}
                      >
                        <Menu />
                      </IconButton>
                      {isEditingWelcome && userRole === 'dominant' ? (
                        <TextField
                          value={newWelcomeMessage}
                          onChange={(e) => setNewWelcomeMessage(e.target.value)}
                          onBlur={handleUpdateWelcomeMessage}
                          variant="standard"
                          sx={{ 
                            minWidth: 200,
                            flex: 1,
                            maxWidth: '50%' // Prevents TextField from pushing controls off screen
                          }}
                          autoFocus
                        />
                      ) : (
                        <Typography 
                          variant="body3" 
                          component="div"
                          onClick={() => userRole === 'dominant' && setIsEditingWelcome(true)}
                          sx={{ 
                            cursor: userRole === 'dominant' ? 'pointer' : 'default',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: 1
                          }}
                        >
                          {welcomeMessage}
                        </Typography>
                      )}
                    </Box>
                  
                    {/* Right side with controls */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      ml: 2, // Adds margin between welcome message and controls
                      flexShrink: 0 // Prevents controls from shrinking
                    }}>
                      <PointsEditor 
                        points={points} 
                        setPoints={updatePoints} 
                        role={userRole} 
                        isLoading={isPointsLoading} 
                      />
                      <ChastityEditor 
                        chastityDays={chastityDays} 
                        setChastityDays={updateChastityDays} 
                        role={userRole} 
                        isLoading={isChastityLoading}
                      />
                      <IconButton color="inherit" onClick={toggleTheme}>
                        {currentTheme === 'dark' ? <Brightness7 /> : <Brightness4 />}
                      </IconButton>
                      <NotificationBell role={userRole} />
                      <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToApp />
                      </IconButton>
                    </Box>
                  </Toolbar>
                ) : (
                  // Mobile Header
                  <>
                    <Toolbar sx={{ 
                      minHeight: { xs: 32, sm: 40 }, // Reduced height
                      padding: { xs: '0 4px', sm: '0 16px' }, // Reduced padding on mobile
                      width: '100%',
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        width: '100%', 
                        gap: { xs: 0.5, sm: 1 } // Reduced gap on mobile
                      }}>
                        {isEditingWelcome && userRole === 'dominant' ? (
                          <>
                            <TextField
                              value={newWelcomeMessage}
                              onChange={(e) => setNewWelcomeMessage(e.target.value)}
                              fullWidth
                              variant="standard"
                            />
                            <IconButton color="inherit" onClick={handleUpdateWelcomeMessage}>
                              <Add />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Typography 
                              variant="body3" 
                              component="div" 
                              sx={{ 
                                flexGrow: 1,
                                fontSize: { xs: '0.9rem', sm: '1.1rem' }
                              }}
                            >
                              {welcomeMessage}
                            </Typography>
                            {userRole === 'dominant' && (
                              <IconButton color="inherit" onClick={() => setIsEditingWelcome(true)}>
                                <Edit />
                              </IconButton>
                            )}
                          </>
                        )}
                      <IconButton color="inherit" onClick={toggleTheme}>
                        {currentTheme === 'dark' ? <Brightness7 /> : <Brightness4 />}
                      </IconButton>
                      <IconButton 
                        color="inherit" 
                        onClick={() => navigate('/chat')}
                        sx={{ position: 'relative' }}
                      >
                        <Badge badgeContent={unreadCount} color="error">
                          <ChatIcon />
                        </Badge>
                      </IconButton>
                      <NotificationBell role={userRole} />
                      <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToApp />
                      </IconButton>
                    </Box>
                    </Toolbar>
                    <Toolbar sx={{ 
                      minHeight: { xs: 32, sm: 40 }, // Reduced height
                      padding: { xs: '0 4px', sm: '0 16px' }, // Reduced padding on mobile
                      width: '100%',
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        width: '100%', 
                        gap: { xs: 0.5, sm: 1 } // Reduced gap on mobile
                      }}>
                        <PointsEditor 
                          points={points} 
                          setPoints={updatePoints} 
                          role={userRole} 
                          isLoading={isPointsLoading} 
                        />
                        <ChastityEditor 
                          chastityDays={chastityDays} 
                          setChastityDays={updateChastityDays} 
                          role={userRole} 
                          isLoading={isChastityLoading}
                        />
                      </Box>
                    </Toolbar>
                    <LinearProgress 
                      variant="determinate" 
                      value={(chastityDays / 30) * 100} 
                      sx={{ 
                        height: { xs: 3, sm: 4 },
                        width: '100%'
                      }} 
                    />
                  </>
                )}
              </AppBar>
                <Container
                disableGutters 
                  sx={{ 
                    mt: { xs: 1, sm: 2, md: 0 }, 
                    mb: { xs: 0, sm: 0, md: 0 },
                    flexGrow: 1,
                    paddingBottom: { xs: 2, sm: 2, md: 0 },
                    paddingLeft:{ xs: 1, sm: 1, md: 1 },
                    paddingRight: { xs: 1, sm: 1, md: 1 },
                    maxWidth: '100% !important',
                    maxWidth: '100vw',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                  }} 
                  ref={contentRef}
                >
                {errorMessage && (
                  <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={2000}
                    onClose={() => setErrorMessage('')}
                    message={errorMessage}
                  />
                )}
                 <Routes>
                   <Route 
                     path="/" 
                     element={
                       isAuth ? (
                         showWizard ? (
                           <WizardPortal>
                             <FirstLoginWizard onComplete={handleWizardComplete} />
                           </WizardPortal>
                         ) : (
                           <Navigate to="/tasks-and-completions" />
                         )
                       ) : (
                         <Login setAuth={(auth) => {
                           setIsAuth(auth);
                           setUserRole(getRole());
                         }} />
                       )
                     } 
                   />
                  <Route
                    path="/dashboard"
                    element={
                      isAuth ? (
                        <Dashboard
                          role={userRole}
                          points={points}
                          chastityDays={chastityDays}
                          setPoints={updatePoints}
                          setChastityDays={updateChastityDays}
                          currentTheme={currentTheme}
                          navStyle={navStyle}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/tasks-and-completions"
                    element={
                      isAuth ? (
                        <TasksAndCompletions
                          role={userRole}
                          points={points}
                          setPoints={updatePoints}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/rewards-and-punishments"
                    element={
                      isAuth ? (
                        <RewardsAndPunishments
                          role={userRole}
                          points={points}
                          setPoints={updatePoints}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/rules-kinks-and-toys"
                    element={
                      isAuth ? (
                        <RulesKinksAndToys
                        role={userRole}
                        currentTheme={currentTheme}
                        setCurrentTheme={setCurrentTheme}
                        submissiveTheme={partnerTheme}
                        setSubmissiveTheme={setPartnerTheme}
                        welcomeMessage={welcomeMessage}
                        setWelcomeMessage={setWelcomeMessage}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/chat"
                    element={
                      isAuth ? (
                        <Chat
                        role={userRole}
                        currentTheme={currentTheme}
                        navStyle={navStyle}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/spot-checks"
                    element={
                      isAuth && userRole === 'dominant' ? (
                        <SpotChecks />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/WorkflowBuilder"
                    element={
                      isAuth && userRole === 'dominant' ? (
                        <WorkflowBuilder 
                        role={userRole}
                        currentTheme={currentTheme}
                        navStyle={navStyle}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                  <Route
                    path="/boardgame"
                    element={
                      isAuth ? (
                        <SafeBoardGame
                        role={userRole}
                        currentTheme={currentTheme}
                        />
                      ) : <Navigate to="/" />
                    }
                  />
                </Routes>
              </Container>
              {!isLargeScreen && (
                <BottomNavigation
                  showLabels
                  value={location.pathname}
                  onChange={(event, newValue) => {
                    navigate(newValue);
                  }}
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderTop: 1,
                    borderColor: 'divider',
                    zIndex: 1000,
                    height: { xs: 56, sm: 64 },
                    '& .MuiBottomNavigationAction-root': {
                      minWidth: '40px',
                      padding: '4px',
                      '& .MuiBottomNavigationAction-label': {
                        fontSize: '0.6rem'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem'
                      }
                    }
                  }}
                >
                  <BottomNavigationAction label="Home" icon={<Home />} value="/dashboard" />
                  <BottomNavigationAction label="Calendar" icon={<CalendarToday />} value="/dashboard#calendar" />
                  <BottomNavigationAction label="Kanban" icon={<ViewKanban />} value="/dashboard#kanban" />
                  <BottomNavigationAction label="Workflow" icon={<AutoFixHighIcon />} value="/WorkflowBuilder" />
                  <BottomNavigationAction label="Tasks" icon={<Assignment />} value="/tasks-and-completions" />
                  <BottomNavigationAction label="Rewards" icon={<EmojiEvents />} value="/rewards-and-punishments" />
                  <BottomNavigationAction label="More" icon={<ListIcon />} value="/rules-kinks-and-toys" />
                </BottomNavigation>
              )}
            </Box>
          </Box>
        </TutorialManager>
      )}
    </ThemeProvider>
  </SettingsProvider>
);
};

const App = () => {
  const [isAuth, setIsAuth] = useState(isAuthenticated());
  const [userRole, setUserRole] = useState(getRole());
  const [showWizard, setShowWizard] = useState(false);

  const handleLogout = () => {
    logout();
    setIsAuth(false);
    setUserRole(null);
  };

  return (
    isAuth ? (
      <SettingsProvider>
        <AppContent 
          isAuth={isAuth} 
          setIsAuth={setIsAuth} 
          userRole={userRole} 
          setUserRole={setUserRole} 
          handleLogout={handleLogout}
          showWizard={showWizard}
          setShowWizard={setShowWizard} 
        />
      </SettingsProvider>
    ) : (
      <ThemeProvider theme={getTheme('dark')}>
        <Login setAuth={(auth) => {
          setIsAuth(auth);
          setUserRole(getRole());
        }} />
      </ThemeProvider>
    )
  );
};

export default App;