import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box, Button, Typography, Dialog, IconButton, Paper,
  CircularProgress, Snackbar, Card, CardContent, Grid,
  Tooltip, Badge, Table, TableBody, TableCell, TableHead, 
  TableRow, LinearProgress, Alert, useMediaQuery, DialogContent,
  DialogTitle, DialogActions, useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import {
  // Existing icons
  Casino, Lock, Timer, EmojiEvents, Gavel,
  SentimentVeryDissatisfied, SentimentVerySatisfied,
  LocalActivity, Star, Whatshot, Extension,
  AccessTime, Block, FastForward, Shuffle,
  ArrowForward, Home, AttachMoney, Casino as DiceIcon,
  EmojiEvents as TrophyIcon, Store, Toys,
  
  // Kink category icons
  Favorite, // Love/Romance
  Psychology, // Mind Control/Hypnosis
  FitnessCenter, // Bondage/Restraints
  Brush, // Impact Play
  ColorLens, // Roleplay
  Visibility, // Exhibitionism
  VisibilityOff, // Denial
  Spa, // Sensation Play
  Pets, // Pet Play
  School, // Education/Training
  LocalHospital, // Medical Play
  Celebration, // Humiliation
  Face, // Face Sitting
  Wc, // Watersports
  AcUnit, // Temperature Play
  Mood, // Oral
  Construction, // CBT
  SportsKabaddi, // Wrestling/Fighting
  Transgender, // Forced Fem
  Compress, // Breath Play
  Height, // Size Play
  EmojiPeople, // Body Worship
  Healing, // Massage
  Anchor, // Rope Play
  Security, // Power Exchange
  Bolt, // Electro
  LocalLaundryService, // Clothing Control
  Restaurant, // Food Play
  Hotel, // Service
  Fingerprint, // Marking
  Camera, // Photography
  VideoCameraFront, // Video
  RecordVoiceOver, // Voice Control
  NoiseControlOff, // Sounds
  Bathtub, // Wet Play
  LocalFlorist, // Sensual
  Brush as PaintBrush, // Body Writing
  Gesture, // Spanking
  SportsMartialArts, // Discipline
  Psychology as MindControl, // Mental Domination
  Favorite as HeartIcon, // Intimacy
  Whatshot as Fire, // Pain
  Star as Pleasure // Pleasure
} from '@mui/icons-material';

// Import background images
import boardBackground from './assets/images/board-background.jpg';
import propertyCardBackground from './assets/images/property-card-bg.jpg';
import cornerSpaceBackground from './assets/images/corner-space-bg.jpg';
import centerLogo from './assets/images/center-logo.png';

const BOARD_SIZE = 40;
const WINNING_MONEY = 5000;
const WINNING_ROUNDS = 20;
const JAIL_DAYS = 7;
const GO_MONEY = 100;
const PROPERTY_BASE_COST = 200;
const TOY_COST = 100;

const SPACE_TYPES = {
  START: { icon: <Home color="primary" />, description: `Collect $${GO_MONEY} when passing GO` },
  JAIL: { icon: <Lock color="error" />, description: `Skip turns for ${JAIL_DAYS} days unless you get a Get Out of Jail card` },
  CHANCE: { icon: <Star color="info" />, description: 'Draw a chance card - Anything can happen!' },
  KINK: { icon: <Whatshot color="secondary" />, description: 'Purchase this kink category to assign punishments/rewards' },
  FREE: { icon: <SentimentVerySatisfied color="success" />, description: 'Safe space - Take a rest' },
  GO_TO_JAIL: { icon: <Block color="error" />, description: 'Go directly to jail - Do not pass GO' }
};

const KINK_ICONS = {
  'Love/Romance': Favorite,
  'Mind Control': Psychology,
  'Bondage': FitnessCenter,
  'Impact Play': Brush,
  'Roleplay': ColorLens,
  'Exhibitionism': Visibility,
  'Denial': VisibilityOff,
  'Sensation Play': Spa,
  'Pet Play': Pets,
  'Training': School,
  'Medical': LocalHospital,
  'Humiliation': Celebration,
  'Face Sitting': Face,
  'Watersports': Wc,
  'Temperature': AcUnit,
  'Oral': Mood,
  'CBT': Construction,
  'Wrestling': SportsKabaddi,
  'Forced Fem': Transgender,
  'Breath Play': Compress,
  'Size Play': Height,
  'Body Worship': EmojiPeople,
  'Massage': Healing,
  'Rope Play': Anchor,
  'Power Exchange': Security,
  'Electro': Bolt,
  'Clothing': LocalLaundryService,
  'Food Play': Restaurant,
  'Service': Hotel,
  'Marking': Fingerprint,
  'Photography': Camera,
  'Video': VideoCameraFront,
  'Voice Control': RecordVoiceOver,
  'Sounds': NoiseControlOff,
  'Wet Play': Bathtub,
  'Sensual': LocalFlorist,
  'Body Writing': PaintBrush,
  'Spanking': Gesture,
  'Discipline': SportsMartialArts,
  'Mental Domination': MindControl,
  'Intimacy': HeartIcon,
  'Pain': Fire,
  'Pleasure': Star
};

// Animation variants
const boardVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1
    }
  }
};

const spaceVariants = {
  hidden: { 
    opacity: 0, 
    scale: 0.8,
    rotateY: -180 
  },
  visible: { 
    opacity: 1, 
    scale: 1,
    rotateY: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const tokenVariants = {
  initial: (position) => ({
    x: position.x,
    y: position.y
  }),
  move: (position) => ({
    x: position.x,
    y: position.y,
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 30
    }
  })
};

const diceVariants = {
  initial: { scale: 1, rotate: 0 },
  rolling: {
    rotate: 360,
    transition: {
      duration: 0.5,
      repeat: Infinity,
      ease: "linear"
    }
  },
  landed: {
    scale: [1.2, 1],
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

const winnerVariants = {
  initial: { scale: 0, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const formatMoney = (amount) => {
  if (amount === undefined || amount === null) {
    return '$0';
  }
  return `$${amount.toLocaleString()}`;
};

const getTimeUntilNextRoll = (lastRollDate) => {
  if (!lastRollDate) return 'Ready to roll!';
  
  const now = new Date();
  const nextRollTime = new Date(lastRollDate);
  nextRollTime.setHours(nextRollTime.getHours() + 24);
  
  const timeLeft = nextRollTime - now;
  if (timeLeft <= 0) return 'Ready to roll!';
  
  const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
  const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  
  return `Next roll in ${hoursLeft}h ${minutesLeft}m`;
};

const getJailTimeRemaining = (jailTimer) => {
  if (!jailTimer) return 'Not in jail';
  
  const now = new Date();
  const releaseDate = new Date(jailTimer);
  const daysLeft = Math.ceil((releaseDate - now) / (1000 * 60 * 60 * 24));
  
  return daysLeft <= 0 ? 'Release pending' : `${daysLeft} days remaining`;
};

// Styled Components for GameBoard
const BoardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  aspectRatio: '1/1',
  margin: '0 auto',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%), linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%)',
  backgroundSize: '60px 60px',
  backgroundPosition: '0 0, 30px 30px',
  border: `8px solid ${theme.palette.primary.dark}`,
  borderRadius: theme.spacing(2),
  boxShadow: `
    inset 0 0 20px rgba(0,0,0,0.2),
    0 10px 20px rgba(0,0,0,0.3)
  `,
  display: 'grid',
  gridTemplateColumns: 'repeat(11, 1fr)',
  gridTemplateRows: 'repeat(11, 1fr)',
  gap: '4px',
  overflow: 'hidden'
}));

const SpaceCard = styled(motion.div)(({ theme, type, owned, ownedByPlayer }) => ({
  background: (() => {
    switch (type) {
      case 'KINK':
        return `${propertyCardBackground ? `url(${propertyCardBackground}),` : ''} linear-gradient(45deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.light} 100%)`;
      case 'CORNER':
        return `${cornerSpaceBackground ? `url(${cornerSpaceBackground}),` : ''} linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`;
      case 'START':
        return `linear-gradient(45deg, ${theme.palette.success.dark} 0%, ${theme.palette.success.main} 100%)`;
      case 'JAIL':
        return `linear-gradient(45deg, ${theme.palette.error.dark} 0%, ${theme.palette.error.main} 100%)`;
      case 'CHANCE':
        return `linear-gradient(45deg, ${theme.palette.info.dark} 0%, ${theme.palette.info.main} 100%)`;
      case 'FREE':
        return `linear-gradient(45deg, ${theme.palette.warning.dark} 0%, ${theme.palette.warning.main} 100%)`;
      case 'GO_TO_JAIL':
        return `linear-gradient(45deg, ${theme.palette.error.main} 0%, ${theme.palette.error.light} 100%)`;
      default:
        return theme.palette.background.paper;
    }
  })(),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    height: '100%',
    minHeight: 120,
    position: 'relative',
    cursor: 'pointer',
    transformStyle: 'preserve-3d',
    transition: 'all 0.3s ease-in-out',
    boxShadow: owned 
      ? `0 0 20px ${ownedByPlayer 
        ? alpha(theme.palette.success.main, 0.5) 
        : alpha(theme.palette.error.main, 0.5)}`
      : '0 8px 16px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      transform: 'translateY(-5px) scale(1.02)',
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)'
    }
})); 

const BoardRow = styled(Box)({
  display: 'flex',
  width: '100%'
});

const BoardMiddle = styled(Box)({
  display: 'flex',
  flex: 1
});

const BoardColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const GridCell = styled(Box)({
  width: '100%',
  height: '100%',
  minWidth: 0, // Allow shrinking
  minHeight: 0,
  position: 'relative'
});

const CardPile = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '150px',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.paper,
    borderRadius: 'inherit',
    border: `1px solid ${theme.palette.divider}`,
  },
  '&::before': {
    transform: 'rotate(-3deg)',
    zIndex: -1,
  },
  '&::after': {
    transform: 'rotate(3deg)',
    zIndex: -2,
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    transition: 'transform 0.2s ease-in-out',
    '&::before': {
      transform: 'rotate(-5deg)',
    },
    '&::after': {
      transform: 'rotate(5deg)',
    },
  }
}));

const PropertyCard = ({ space, playerType, onPurchase, onPlaceToy }) => {
  const theme = useTheme();
  const KinkIcon = KINK_ICONS[space?.category];

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          {KinkIcon && <KinkIcon sx={{ mr: 1 }} />}
          <Typography variant="h6">{space?.category}</Typography>
        </Box>
        
        {!space?.owned && (
          <Button
            variant="contained"
            color="primary"
            onClick={onPurchase}
            fullWidth
            sx={{ mb: 1 }}
          >
            Purchase for ${PROPERTY_BASE_COST}
          </Button>
        )}
        
        {space?.owned === playerType && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onPlaceToy(space)}
            fullWidth
            sx={{ mb: 1 }}
          >
            Add Toy (${TOY_COST})
          </Button>
        )}
        
        {space?.toys?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">
              Toys Placed: {space.toys.length}
            </Typography>
            <Typography variant="subtitle2">
              Current Multiplier: x{space.toys.length + 1}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const CornerSpace = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(1),
  backgroundColor: type === 'START' ? theme.palette.success.light :
                  type === 'JAIL' ? theme.palette.error.light :
                  type === 'FREE' ? theme.palette.warning.light :
                  theme.palette.error.light
}));

const CenterArea = styled(Box)(({ theme }) => ({
  gridColumn: '2 / 11',
  gridRow: '2 / 11',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  '& .card-pile': {
    position: 'relative',
    height: '150px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const StyledCard = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  width: '120px',
  height: '180px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  backfaceVisibility: 'hidden'
}));

const PunishmentCard = ({ punishment, isFlipping, onFlipComplete }) => {
  return (
    <motion.div
      initial={{ scale: 0.1, rotateY: 0 }}
      animate={{ 
        scale: isFlipping ? 1 : 0.1,
        rotateY: isFlipping ? 180 : 0,
        z: isFlipping ? 50 : 0
      }}
      transition={{
        duration: 1,
        ease: "easeInOut"
      }}
      onAnimationComplete={onFlipComplete}
    >
      <StyledCard>
        <Typography variant="h6">{punishment.title}</Typography>
        <Typography>{punishment.description}</Typography>
        <Typography variant="caption">
          Points: {punishment.points_earned}
        </Typography>
      </StyledCard>
    </motion.div>
  );
};

const CenterLogo = styled(motion.div)(({ theme }) => ({
  width: '10%',
  height: '10%',
  background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.contrastText,
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
}));

const PlayerToken = styled('div')(({ theme, playerType }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: playerType === 'dominant' ? theme.palette.error.main : theme.palette.success.main,
  border: `3px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  position: 'absolute',
  bottom: theme.spacing(1),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 2,
  '&::after': {
    content: `"${playerType === 'dominant' ? 'D' : 'S'}"`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '14px'
  }
}));

const DiceRoll = ({ rolling, value, onRollComplete }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (rolling) {
      const interval = setInterval(() => {
        setDisplayValue(Math.floor(Math.random() * 6) + 1);
        setRotation(prev => prev + 360);
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        setDisplayValue(value);
        onRollComplete?.();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [rolling, value, onRollComplete]);

  return (
    <Paper
      elevation={rolling ? 8 : 3}
      sx={{
        width: 80,
        height: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        borderRadius: 2,
        transition: 'all 0.1s ease-in-out',
        transform: `rotate(${rotation}deg)`,
        backgroundColor: theme => rolling ? theme.palette.primary.light : theme.palette.background.paper,
        color: theme => rolling ? theme.palette.primary.contrastText : theme.palette.text.primary,
        cursor: 'pointer'
      }}
    >
      {displayValue}
    </Paper>
  );
};

const GameSpace = ({ 
  space, 
  position, 
  isCurrentPosition, 
  isPlayerSpace, 
  onClick,
  playerType 
}) => {
  const icon = SPACE_TYPES[space.type]?.icon || <Star />;
  const isOwned = space.type === 'KINK' && space.owned;
  const ownedByPlayer = isOwned && space.owned === playerType;

  return (
    <Tooltip title={
      <Box>
        <Typography variant="body2">{space.name}</Typography>
        {space.type === 'KINK' && (
          <>
            <Typography variant="caption" display="block">
              {isOwned ? `Owned by: ${space.owned}` : 'Available'}
            </Typography>
            {space.toys?.length > 0 && (
              <Typography variant="caption" display="block">
                Toys: {space.toys.length} (x{space.multiplier})
              </Typography>
            )}
          </>
        )}
      </Box>
    }>
      <Paper
        elevation={isCurrentPosition ? 8 : 1}
        onClick={onClick}
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: 100,
          cursor: 'pointer',
          border: theme => {
            if (isCurrentPosition) return `2px solid ${theme.palette.primary.main}`;
            if (ownedByPlayer) return `2px solid ${theme.palette.success.main}`;
            if (isOwned) return `2px solid ${theme.palette.error.main}`;
            return 'none';
          },
          backgroundColor: theme => {
            if (isCurrentPosition) return `${theme.palette.primary.main}22`;
            if (ownedByPlayer) return `${theme.palette.success.main}22`;
            if (isOwned) return `${theme.palette.error.main}22`;
            return 'background.paper';
          },
          position: 'relative',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: 6
          }
        }}
      >
        <Box sx={{ mb: 1 }}>
          {icon}
        </Box>
        <Typography variant="caption" align="center">
          {position}
        </Typography>
        {space.type === 'KINK' && space.toys?.length > 0 && (
          <Badge
            badgeContent={space.toys.length}
            color="primary"
            sx={{ position: 'absolute', top: 4, right: 4 }}
          >
            <Toys fontSize="small" />
          </Badge>
        )}
      </Paper>
    </Tooltip>
  );
};

const PropertySpace = styled(Box)(({ theme, owned, ownedBy }) => ({
  width: '100%',
  height: '100%',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: theme.spacing(0.5), // Reduced padding
  fontSize: '0.8rem', // Smaller font size
  backgroundColor: owned ? 
    (ownedBy === 'dominant' ? alpha(theme.palette.error.main, 0.1) : 
     ownedBy === 'submissive' ? alpha(theme.palette.success.main, 0.1) : 
     'transparent') : 
    'transparent',
  '& .property-header': {
    height: '20px', // Reduced height
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .property-content': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5)
  },
  '& .property-price': {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    width: '100%',
    textAlign: 'center',
    fontSize: '0.7rem'
  },
  '& .property-toys': {
    position: 'absolute',
    top: '20px',
    right: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  }
}));

const GameEventModal = ({ event, onClose }) => {
  if (!event) return null;

  return (
    <Dialog 
      open={true} 
      onClose={onClose}
      TransitionComponent={motion.div}
      TransitionProps={{
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 },
        transition: { duration: 0.3 }
      }}
    >
      <DialogTitle>{event.title}</DialogTitle>
      <DialogContent>
        <Typography>{event.description}</Typography>
        {event.type === 'punishment' && (
          <Box sx={{ mt: 2 }}>
            <Typography color="error" variant="h6">Punishment Assigned</Typography>
            <Typography>{event.details.title}</Typography>
            <Typography variant="caption">{event.details.description}</Typography>
          </Box>
        )}
        {event.type === 'reward' && (
          <Box sx={{ mt: 2 }}>
            <Typography color="success.main" variant="h6">Reward Granted</Typography>
            <Typography>{event.details.title}</Typography>
            <Typography variant="caption">{event.details.description}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

const FullBoard = ({ game }) => {
  const theme = useTheme();
  const boardLayout = {
    TOP: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    RIGHT: [31, 32, 33, 34, 35, 36, 37, 38, 39],
    BOTTOM: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    LEFT: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  };

  return (
    <BoardContainer
      variants={boardVariants}
      initial="hidden"
      animate="visible"
    >
      <Grid container spacing={1}>
        {/* Top Row */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {boardLayout.TOP.map(index => (
              <Grid item xs={12/11} key={index}>
                <GameSpace 
                  space={game.game_state.board[index]} 
                  position={index}
                  isCurrentPosition={index === game.current_position}
                  playerType={game.current_player_type}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Middle Section */}
        <Grid item xs={12} sx={{ flex: 1 }}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            {/* Left Side */}
            <Grid item xs={1}>
              <Grid container direction="column" spacing={1}>
                {boardLayout.LEFT.map(index => (
                  <Grid item xs key={index}>
                    <GameSpace 
                      space={game.game_state.board[index]} 
                      position={index}
                      isCurrentPosition={index === game.current_position}
                      playerType={game.current_player_type}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Right Side */}
            <Grid item xs={1}>
              <Grid container direction="column" spacing={1}>
                {boardLayout.RIGHT.map(index => (
                  <Grid item xs key={index}>
                    <GameSpace 
                      space={game.game_state.board[index]} 
                      position={index}
                      isCurrentPosition={index === game.current_position}
                      playerType={game.current_player_type}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Bottom Row */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {boardLayout.BOTTOM.map(index => (
              <Grid item xs={12/11} key={index}>
                <GameSpace 
                  space={game.game_state.board[index]} 
                  position={index}
                  isCurrentPosition={index === game.current_position}
                  playerType={game.current_player_type}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </BoardContainer>
  );
};

const BoardView = ({ game, isMobile, currentPosition, playerType, onSpaceClick }) => {
  const [showFullBoard, setShowFullBoard] = useState(!isMobile);
  
  useEffect(() => {
    if (isMobile && game.lastRoll) {
      // Show landing space and neighbors
      setShowFullBoard(false);
      setTimeout(() => setShowFullBoard(true), 5000);
    }
  }, [game.lastRoll, isMobile]);

  if (isMobile && !showFullBoard) {
    // Show just the relevant spaces
    const prevSpace = (currentPosition - 1 + BOARD_SIZE) % BOARD_SIZE;
    const nextSpace = (currentPosition + 1) % BOARD_SIZE;
    
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Grid container spacing={1}>
          {[prevSpace, currentPosition, nextSpace].map(pos => (
            <Grid item xs={4} key={pos}>
              <GameSpace 
                space={game.game_state.board[pos]} 
                position={pos}
                isCurrentPosition={pos === currentPosition}
                isPlayerSpace={false}
                onClick={() => onSpaceClick(game.game_state.board[pos], pos)}
                playerType={playerType}
              />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => setShowFullBoard(true)}
            startIcon={<Visibility />}
          >
            Show Full Board
          </Button>
        </Box>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <FullBoard 
        game={game}
        currentPosition={currentPosition}
        playerType={playerType}
        onSpaceClick={onSpaceClick}
      />
      {isMobile && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => setShowFullBoard(false)}
            startIcon={<VisibilityOff />}
          >
            Show Focused View
          </Button>
        </Box>
      )}
    </motion.div>
  );
};

const GameBoard = ({ game, userType, onSpaceClick, onDrawCard }) => {
  const theme = useTheme();
  
  const boardLayout = {
    top: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    right: [31, 32, 33, 34, 35, 36, 37, 38, 39],
    bottom: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    left: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  };

  if (!game || !game.game_state || !game.game_state.board) {
    return <CircularProgress />;
  }

  const getTextRotation = (index) => {
    // if (boardLayout.top.includes(index)) return 'rotate(180deg)';
    // if (boardLayout.right.includes(index)) return 'rotate(270deg)';
    // if (boardLayout.left.includes(index)) return 'rotate(90deg)';
    return 'rotate(0deg)';
  };

  const renderSpace = (index) => {
    const space = game.game_state.board[index];
    if (!space) {
      console.error(`No space found for index ${index}`);
      return null;
    }

    const isCorner = [0, 10, 20, 30].includes(index);
    const KinkIcon = space.type === 'KINK' ? KINK_ICONS[space.category] : null;
    
    return (
      <PropertySpace
        owned={space.owned_by}
        ownedBy={space.owned_by}
        corner={isCorner}
        onClick={() => onSpaceClick(space, index)}
      >
        {space.type === 'KINK' ? (
          <>
            <div className="property-header" 
              style={{ 
                backgroundColor: theme.palette.primary[space.owned_by ? 'dark' : 'main'] 
              }}
            >
              {KinkIcon && <KinkIcon sx={{ fontSize: '1.5rem', color: 'white' }} />}
            </div>
            <div className="property-content">
              <Typography 
                variant="caption" 
                align="center" 
                sx={{ 
                  fontSize: '0.7rem',
                  transform: getTextRotation(index),
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%'
                }}
              >
                {space.category || space.name}
              </Typography>
              {space.price && (
                <Typography className="property-price">
                  ${space.price}
                </Typography>
              )}
            </div>
          </>
        ) : (
          <CornerSpace type={space.type}>
            {SPACE_TYPES[space.type]?.icon}
            <Typography variant="caption">
              {space.name}
            </Typography>
          </CornerSpace>
        )}
        
        {/* Player tokens */}
        {game.dominant_position === index && (
          <PlayerToken 
            playerType="dominant" 
            animate={{ scale: [0.8, 1, 0.8] }} 
            transition={{ repeat: Infinity, duration: 2 }}
          />
        )}
        {game.submissive_position === index && (
          <PlayerToken 
            playerType="submissive"
            animate={{ scale: [0.8, 1, 0.8] }} 
            transition={{ repeat: Infinity, duration: 2 }}
          />
        )}
      </PropertySpace>
    );
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
      <BoardContainer>
        {/* Top row */}
        {Array.from({ length: 11 }, (_, i) => (
          <GridCell key={`top-${i}`}>
            {renderSpace(20 + i)}
          </GridCell>
        ))}

        {/* Right column */}
        {Array.from({ length: 9 }, (_, i) => (
          <GridCell key={`right-${i}`} style={{ gridColumn: 11, gridRow: i + 2 }}>
            {renderSpace(31 + i)}
          </GridCell>
        ))}

        {/* Bottom row */}
        {Array.from({ length: 11 }, (_, i) => (
          <GridCell key={`bottom-${i}`} style={{ gridColumn: 11 - i, gridRow: 11 }}>
            {renderSpace(19 - i)}
          </GridCell>
        ))}

        {/* Left column */}
        {Array.from({ length: 9 }, (_, i) => (
          <GridCell key={`left-${i}`} style={{ gridColumn: 1, gridRow: 10 - i }}>
            {renderSpace(9 - i)}
          </GridCell>
        ))}

        {/* Center area */}
        <Box
          sx={{
            gridColumn: '2 / 11',
            gridRow: '2 / 11',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2,
            padding: 2,
            position: 'relative'
          }}
        >
          <CardPile onClick={() => onDrawCard('chance')}>
            <Typography variant="h6">Chance</Typography>
          </CardPile>
          
          <CardPile onClick={() => onDrawCard('punishment')}>
            <Typography variant="h6">Punishments</Typography>
          </CardPile>
          
          {centerLogo && (
            <Box
              component="img"
              src={centerLogo}
              alt="Strictly"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '120px',
                height: 'auto'
              }}
            />
          )}
        </Box>
      </BoardContainer>
    </Box>
  );
};

const BoardGame = () => {
  // Original state
  const [game, setGame] = useState({
    game_state: {
      board: [],
    },
    dominant_money: 0,
    submissive_money: 0,
    dominant_position: 0,
    submissive_position: 0,
    can_roll: false,
    last_roll_date: null,
    dominant_in_jail: false,
    submissive_in_jail: false,
    jail_timer_dominant: null,
    jail_timer_submissive: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [rolling, setRolling] = useState(false);
  const [diceValue, setDiceValue] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [showFullBoard, setShowFullBoard] = useState(false);
  const [gameEvent, setGameEvent] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [kinks, setKinks] = useState([]);
  const [hasMultipleRolls, setHasMultipleRolls] = useState(false);
  const [chanceCards, setChanceCards] = useState([]);
  const [drawingPunishment, setDrawingPunishment] = useState(false);
  const [punishmentCard, setPunishmentCard] = useState(null);
  
  // New state for proper user identification
  const [userType, setUserType] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [playerType, setPlayerType] = useState(null);

  const [boardLayout, setBoardLayout] = useState({
    TOP: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    RIGHT: [31, 32, 33, 34, 35, 36, 37, 38, 39],
    BOTTOM: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    LEFT: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  });

  const isMobile = useMediaQuery('(max-width:600px)');

useEffect(() => {
  let mounted = true;
  
  const initializeGame = async () => {
    console.log("Initializing game...");
    try {
      // Get user profile first to determine user type
      console.log("Fetching user profile...");
      const userResponse = await axiosInstance.get('/user-profile');
      const userType = userResponse.data.user_type;
      console.log("User type:", userType);
      
      console.log("Fetching game state...");
      const gameResponse = await axiosInstance.get('/monopoly/game/current');
      console.log("Game state response:", gameResponse.data);
      
      console.log("Fetching kinks...");
      const kinksResponse = await axiosInstance.get('/kinks');
      console.log("Kinks response:", kinksResponse.data);
      
      console.log("Fetching chance cards...");
      const cardsResponse = await axiosInstance.get('/monopoly/game/chance-cards');
      console.log("Cards response:", cardsResponse.data);
      
      if (mounted) {
        setUserType(userType);
        setPlayerType(userType);
        setCurrentPlayer(gameResponse.data.current_turn);
        setGame(gameResponse.data);
        setKinks(kinksResponse.data);
        setChanceCards(cardsResponse.data);
        setInitialized(true);
        setLoading(false);  // Make sure we're setting loading to false
        console.log("Game initialized successfully");
      }
    } catch (error) {
      console.error("Game initialization error:", error);
      if (mounted) {
        setError('Failed to initialize game');
        setLoading(false);  // Make sure we set loading to false even on error
      }
    }
  };

  initializeGame();

  return () => {
    mounted = false;
  };
}, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  const fetchGameState = async () => {
    try {
      const response = await axiosInstance.get('/monopoly/game/current');
      setGame(response.data);
      setPlayerType(response.data.current_user_type);
      setLoading(false);
    } catch (error) {
      setError('Failed to load game state');
      setLoading(false);
    }
  };

  const fetchKinks = async () => {
    try {
      const response = await axiosInstance.get('/kinks');
      setKinks(response.data);
    } catch (error) {
      setError('Failed to load kink categories');
    }
  };

  const fetchChanceCards = async () => {
    try {
      const response = await axiosInstance.get('/monopoly/game/chance-cards');
      setChanceCards(response.data);
    } catch (error) {
      setError('Failed to load chance cards');
    }
  };

  const generateBoard = () => {
    if (!game || !game.game_state) return;

    const newBoard = game.game_state.board.map((space, index) => {
      if (space.type === 'KINK') {
        const kink = kinks.find(k => k.category === space.category);
        return {
          ...space,
          kink,
          multiplier: (space.toys?.length || 0) + 1
        };
      }
      return space;
    });

    setGame(prev => ({
      ...prev,
      game_state: {
        ...prev.game_state,
        board: newBoard
      }
    }));
  };

  const PunishmentCardDisplay = () => {
    if (!punishmentCard) return null;

    return (
      <Dialog
        open={!!punishmentCard}
        TransitionComponent={motion.div}
        TransitionProps={{
          initial: { opacity: 0, scale: 0.5 },
          animate: { opacity: 1, scale: 1 },
          exit: { opacity: 0, scale: 0.5 }
        }}
      >
        <DialogContent>
          <PunishmentCard
            punishment={punishmentCard.punishment}
            isFlipping={punishmentCard.isFlipping}
            onFlipComplete={() => {
              if (punishmentCard.onComplete) {
                punishmentCard.onComplete();
              }
              setPunishmentCard(null);
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

// Game Logic Functions

  const handleMove = async (position) => {
    try {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/move`, {
        position
      });
      setGame(response.data.game);
      await handleLandOnSpace(response.data.game.game_state.board[position], position);
    } catch (error) {
      setError('Failed to move player');
    }
  };
  
  const handleMoneyChange = async (amount) => {
    try {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/money`, {
        amount
      });
      setGame(response.data.game);
      setSnackbar({
        open: true,
        message: `${amount > 0 ? 'Received' : 'Paid'} ${formatMoney(Math.abs(amount))}`
      });
    } catch (error) {
      setError('Failed to process money change');
    }
  };

  const canMakeMove = () => {
    return userType === currentPlayer && game.can_roll;
  };

  const handleRoll = async () => {
      if (!canMakeMove() || rolling || (!game.can_roll && !hasMultipleRolls)) return;
  
      try {
          setRolling(true);
          const roll = Math.floor(Math.random() * 6) + 1;
          setDiceValue(roll);
  
          const response = await axiosInstance.post(`/monopoly/game/${game.id}/roll`, {
              roll: roll  // Send the roll value to backend
          });
  
          if (isMobile) {
              setShowFullBoard(true);
          }
  
          setTimeout(async () => {
              setRolling(false);
              setGame(response.data.game);
              
              if (response.data.landed_on) {
                  await handleLandOnSpace(response.data.landed_on, response.data.landed_on.position);
              }
  
              if (isMobile) {
                  setTimeout(() => setShowFullBoard(false), 5000);
              }
          }, 2000);
      } catch (error) {
          setRolling(false);
          setError('Failed to process roll');
      } finally {
          if (hasMultipleRolls) {
              setHasMultipleRolls(false);
          }
      }
  };

  const handleLandOnSpace = async (space, position) => {
    if (!space || !userType) return;
  
    try {
      if (space.type === 'KINK') {
        const currentPosition = userType === 'dominant' ? game.dominant_position : game.submissive_position;
        
        if (space.owned) {
          if (space.owned !== userType) {
            // Landing on opponent's property
            if (userType === 'submissive') {
              // Draw punishments based on multiplier
              const multiplier = (space.toys?.length || 0) + 1;
              setDrawingPunishment(true);
              
              const response = await axiosInstance.post('/random-punishment', {
                category: space.category,
                count: multiplier
              });
  
              // Animate punishment cards
              for (const punishment of response.data.punishments) {
                await new Promise(resolve => {
                  setPunishmentCard({
                    punishment,
                    isFlipping: true,
                    onComplete: resolve
                  });
                });
              }
              
              setDrawingPunishment(false);
            } else {
              // Dominant landing on submissive's property - grant reward
              const response = await axiosInstance.post('/random-reward', {
                category: space.category
              });
              
              setGameEvent({
                title: 'Reward Granted',
                description: `You've earned a reward from ${space.category}!`,
                details: response.data
              });
            }
          }
        } else if (position === currentPosition && game[`${userType}_money`] >= PROPERTY_BASE_COST) {
          // Can only purchase if we've landed on it and have enough money
          setSelectedSpace({ ...space, position });
          setGameEvent({
            title: 'Purchase Available',
            description: `Would you like to purchase ${space.category} for ${formatMoney(PROPERTY_BASE_COST)}?`,
            type: 'purchase'
          });
        }
      } else if (space.type === 'CHANCE') {
        const response = await axiosInstance.post(`/monopoly/game/${game.id}/chance`);
        setGameEvent({
          title: 'Chance Card',
          description: response.data.card.text,
          type: 'chance',
          details: response.data.card
        });
      } else if (space.type === 'JAIL') {
        await handleJailSpace();
      } else if (space.type === 'GO_TO_JAIL') {
        await handleGoToJailSpace();
      } else if (space.type === 'START') {
        await handleStartSpace();
      }
  
      // Update game state after processing space
      const updatedGame = await axiosInstance.get('/monopoly/game/current');
      setGame(updatedGame.data);
  
    } catch (error) {
      console.error('Error handling land on space:', error);
      setError('Failed to process space effect');
    }
  };

  const handleKinkSpace = async (space, position) => {
    setSelectedSpace({ ...space, position });
    
    if (!space.owned) {
      if (game[`${playerType}_money`] >= PROPERTY_BASE_COST) {
        setGameEvent({
          title: 'Purchase Available',
          description: `Would you like to purchase ${space.category} for ${formatMoney(PROPERTY_BASE_COST)}?`,
          type: 'purchase',
          space
        });
      }
    } else if (space.owned !== playerType) {
      try {
        const endpoint = playerType === 'submissive' ? '/random-punishment' : '/random-reward';
        const response = await axiosInstance.post(endpoint, {
          category: space.category
        });
        
        const multiplier = space.multiplier || 1;
        const details = {
          ...response.data,
          points: (response.data.points || 0) * multiplier
        };
        
        setGameEvent({
          title: playerType === 'submissive' ? 'Punishment Assigned' : 'Reward Granted',
          description: `${space.owned} owns this kink! ${multiplier}x effect applied.`,
          type: playerType === 'submissive' ? 'punishment' : 'reward',
          details
        });

        if (playerType === 'submissive') {
          await handlePunishmentAssignment(details);
        } else {
          await handleRewardAssignment(details);
        }
      } catch (error) {
        setError('Failed to process space effect');
      }
    }
  };

  const handleChanceCard = async () => {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/chance`);
      const card = response.data.card;
      setGame(response.data.game);
      setGameEvent(response.data.effect);
  };

  const processChanceCardEffects = async (card) => {
    switch (card.type) {
      case 'CHASTITY':
        await handleChastityModification(card.value);
        break;
      case 'WISHLIST':
        await handleWishlistReward(card.details);
        break;
      case 'MULTIPLE_ROLLS':
        setHasMultipleRolls(true);
        break;
      case 'MOVE':
        await handleMove(card.value);
        break;
      case 'MONEY':
        await handleMoneyChange(card.value);
        break;
      default:
        break;
    }
  };

  const handleChastityModification = async (days) => {
    const endpoint = days > 0 ? '/chastity/increment' : '/chastity/decrement';
    try {
      await axiosInstance.post(endpoint, {
        days: Math.abs(days)
      });
      setSnackbar({
        open: true,
        message: `Chastity period ${days > 0 ? 'increased' : 'decreased'} by ${Math.abs(days)} days`
      });
    } catch (error) {
      setError('Failed to modify chastity period');
    }
  };

  const handleWishlistReward = async (reward) => {
    try {
      await axiosInstance.post(`/wishlist/${reward.id}/purchase`, {
        from_game: true
      });
      setSnackbar({
        open: true,
        message: 'Wishlist item marked for purchase!'
      });
    } catch (error) {
      setError('Failed to process wishlist reward');
    }
  };

  const handlePunishmentAssignment = async (punishment) => {
    try {
      await axiosInstance.post('/punishments', {
        ...punishment,
        from_game: true
      });
    } catch (error) {
      setError('Failed to assign punishment');
    }
  };

  const handleRewardAssignment = async (reward) => {
    try {
      await axiosInstance.post('/rewards', {
        ...reward,
        from_game: true
      });
    } catch (error) {
      setError('Failed to assign reward');
    }
  };

// Property Management Functions
  const handlePurchaseProperty = async () => {
    if (!selectedSpace) return;

    try {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/purchase`, {
        position: selectedSpace.position,
        cost: PROPERTY_BASE_COST
      });

      setGame(response.data.game);
      setSnackbar({
        open: true,
        message: `Purchased ${selectedSpace.category} for ${formatMoney(PROPERTY_BASE_COST)}`
      });
    } catch (error) {
      setError('Failed to purchase property');
    } finally {
      setSelectedSpace(null);
    }
  };

  const handlePlaceToy = async (space) => {
    try {
      const response = await axiosInstance.post(`/monopoly/space/${space.position}/add-toy`, {
        cost: TOY_COST
      });
      
      if (response.data.success) {
        // Update game state with new toy
        const newGame = {...game};
        newGame.board[space.position].toys.push({
          id: response.data.toyId,
          type: 'basic'
        });
        setGame(newGame);
        
        setSnackbar({
          open: true,
          message: `Toy placed on ${space.category}! Multiplier is now x${newGame.board[space.position].toys.length + 1}`
        });
      }
    } catch (error) {
      console.error('Error placing toy:', error);
    }
  };

  const handleJailSpace = async () => {
    await handleChastityModification(JAIL_DAYS);
    setGameEvent({
      title: 'Jail',
      description: `You've been sent to jail for ${JAIL_DAYS} days!`,
      type: 'jail'
    });
  };

  const handleGoToJailSpace = async () => {
    try {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/go-to-jail`);
      setGame(response.data.game);
      await handleJailSpace();
    } catch (error) {
      setError('Failed to process go to jail');
    }
  };

  const handleStartSpace = async () => {
    try {
      const response = await axiosInstance.post(`/monopoly/game/${game.id}/pass-go`);
      setGame(response.data.game);
      setSnackbar({
        open: true,
        message: `Collected ${formatMoney(GO_MONEY)} for passing GO!`
      });
    } catch (error) {
      setError('Failed to process GO space');
    }
  };

  const handleDrawCard = async (cardType) => {
    try {
      if (cardType === 'chance') {
        const response = await axiosInstance.post(`/monopoly/game/${game.id}/chance`);
        setGameEvent({
          title: 'Chance Card',
          description: response.data.card.text,
          type: 'chance',
          details: response.data.card
        });
        await processChanceCardEffects(response.data.card);
      } else if (cardType === 'punishment') {
        setDrawingPunishment(true);
        try {
          const response = await axiosInstance.post('/random-punishment');
          setPunishmentCard({
            punishment: response.data,
            isFlipping: true,
            onComplete: () => {
              setDrawingPunishment(false);
              handlePunishmentAssignment(response.data);
            }
          });
        } catch (error) {
          setDrawingPunishment(false);
          setError('Failed to draw punishment card');
        }
      }
    } catch (error) {
      console.error('Error drawing card:', error);
      setError(`Failed to draw ${cardType} card`);
    }
  };

  // Render Conditions
  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!game) return <Typography>No active game found</Typography>;

  // Main Render
  return (
    <Box sx={{ p: 2 }}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {playerType === 'dominant' ? 'Dominant' : 'Submissive'}
              </Typography>
              <Typography>Money: {formatMoney(game[`${playerType}_money`])}</Typography>
              <Typography>
                Position: {playerType === 'dominant' ? game.dominant_position : game.submissive_position}
              </Typography>
              <Typography>
                Next Roll: {getTimeUntilNextRoll(game.last_roll_date)}
              </Typography>
              {(playerType === 'dominant' ? game.dominant_in_jail : game.submissive_in_jail) && (
                <Typography color="error">
                  In Jail - {getJailTimeRemaining(playerType === 'dominant' ? game.jail_timer_dominant : game.jail_timer_submissive)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DiceRoll
                  rolling={rolling}
                  value={diceValue}
                  onRollComplete={() => setRolling(false)}
                />
                <Button
                  variant="contained"
                  startIcon={<Casino />}
                  onClick={handleRoll}
                  disabled={rolling || (!game.can_roll && !hasMultipleRolls)}
                  sx={{ ml: 2 }}
                >
                  Roll Dice
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    <GameBoard
      game={game}
      userType={userType}
      playerType={playerType}
      onSpaceClick={(space, position) => setSelectedSpace({ ...space, position })}
      onDrawCard={handleDrawCard}
    />

    <PunishmentCardDisplay />

    <GameEventModal
      event={gameEvent}
      onClose={() => setGameEvent(null)}
    />

      <Dialog open={!!selectedSpace} onClose={() => setSelectedSpace(null)}>
        <DialogContent>
          <PropertyCard
            space={selectedSpace || {}}
            playerType={playerType}
            onPurchase={handlePurchaseProperty}
            onPlaceToy={handlePlaceToy}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

class GameErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Game Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6" color="error">
            Something went wrong with the game.
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => window.location.reload()}
            sx={{ mt: 2 }}
          >
            Reload Game
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

const SafeBoardGame = () => {
  return (
    <GameErrorBoundary>
      <BoardGame />
    </GameErrorBoundary>
  );
};

export default SafeBoardGame;